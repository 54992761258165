<template>
    <div>
        <c-gallery
                :images="original"
                :index="index"
                @close="index = null"
                :id="`gallery_${shiftId}`"
                :key="`gallery_${shiftId}`"
        />
        <img :src="image" alt="" v-for="(image, imageIndex) in mini" :key="imageIndex"  @click="index = imageIndex">
    </div>
</template>

<script>
    import VueGallery from 'vue-gallery';

    export default {
        name: "Images",
        components: {
            cGallery: VueGallery
        },
        computed: {
            original() {
                return this.images.map(el => `/images/${el}`);
            },
            mini() {
                return this.images.map(el => `/images/mini/${el}`);
            }
        },
        data() {
            return {
                index: null
            }
        },
        props: {
            shiftId: Number,
            images: Array
        }
    }
</script>

<style>
    img {
        cursor: pointer;
        height: auto;
        width: 150px;
        margin: 10px;
    }
    .blueimp-gallery-display {
        display: none;
    }
</style>