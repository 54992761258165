import CompletedWorks from "../views/CompletedWorks/CompletedWorks.vue";
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Users from '../views/Users/Users.vue'
import Login from '../views/Login.vue'
import Objects from '../views/Objects/Objects.vue'
import UserChats from '../views/UserChats.vue'
import SecondWorks from "../views/SecondWorks/SecondWorks.vue";
import SystemUsers from "../views/SystemUsers/SystemUsers.vue";
import SystemUserForm from "../views/SystemUsers/SystemUserForm/SystemUserForm.vue";
import Targets from "../views/Targets/Targets.vue";
import TypesOfWorks from '../views/TypesWork.vue'
import store from '../store/store';
import ObjectsInProcess from "../views/ObjectsInProcess/ObjectsInProcess.vue";
import WorkTypes from "../views/WorkTypes/WorkTypes.vue";
import WorkShifts from "../views/WorkShifts/WorkShifts.vue";
import FuelLimits from "../views/FuelLimits/FuelLimits.vue";
import UsedFuels from "../views/UsedFuels/UsedFuels.vue";
import CreateObjectWizard from "../views/CreateObjectWizard/CreateObjectWizard.vue";
import WorkGroups from "../views/WorkGroups/WorkGroups.vue";
import MaterialDirectory from "../views/Materials/Directory/MaterialDirectory.vue";
import MaterialGroups from "../views/Materials/Groups/MaterialGroups.vue";
import MaterialTargets from "../views/Materials/Targets/MaterialTargets.vue";
import MaterialUsing from "../views/Materials/Using/MaterialUsing.vue";
import Charts from "../views/Charts/Charts.vue";
import Gallery from "../views/Gallery/Gallery.vue";
import ReportsManagement from "../views/ReportsManagement/ReportsManagement.vue";
import AlertsManagement from "../views/AlertsManagement/AlertsManagement.vue";
import UserGroups from "@/views/UserGroups/UserGroups";
import TelegramDatepicker from "@/views/TelegramDatepicker/TelegramDatepicker";
import Tasks from "@/views/Tasks/Tasks";
import TaskCard from "@/views/Tasks/TaskCard/TaskCard";

Vue.use(VueRouter);

const routes = [
    {
        path: '*',
        component: Home,
        meta: {requiresAuth: true}
    },
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {requiresAuth: true}
    },
    {
        path: '/completed-works',
        name: 'CompletedWorks',
        component: CompletedWorks,
        meta: {requiresAuth: true}
    },
    {
        path: '/create-object-wizard',
        name: 'CreateObjectWizard',
        component: CreateObjectWizard,
        meta: {requiresAuth: true}
    },
    {
        path: '/fuel-limits',
        name: 'FuelLimits',
        component: FuelLimits,
        meta: {requiresAuth: true}
    },
    {
        path: `/systemUsers`,
        name: `SystemUsers`,
        component: SystemUsers,
        meta: {requiresAuth: true}
    },
    {
        path: `/systemUserForm`,
        name: `SystemUserForm`,
        component: SystemUserForm,
        meta: {requiresAuth: true}
    },
    {
        path: `/targets`,
        name: `Targets`,
        component: Targets,
        meta: {requiresAuth: true}
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        meta: {requiresAuth: true}
    },
    {
        path: '/objects',
        name: 'objects',
        component: Objects,
        meta: {requiresAuth: true}
    },
    {
        path: '/objects-in-process',
        name: 'objectsInProcess',
        component: ObjectsInProcess,
        meta: {requiresAuth: true}
    },
    {
        path: '/used-fuels',
        name: 'UsedFuels',
        component: UsedFuels,
        meta: {requiresAuth: true}
    },
    {
        path: '/user-chats',
        name: 'user-chats',
        component: UserChats,
        meta: {requiresAuth: true}
    },
    {
        path: '/second-works',
        name: 'second-works',
        component: SecondWorks,
        meta: {requiresAuth: true}
    },
    {
        path: '/types-of-works',
        name: 'types-of-works',
        component: TypesOfWorks,
        meta: {requiresAuth: true}
    },
    {
        path: '/work-types',
        name: 'workTypes',
        component: WorkTypes,
        meta: {requiresAuth: true}
    },
    {
        path: '/work-groups',
        name: 'workGroups',
        component: WorkGroups,
        meta: {requiresAuth: true}
    },
    {
        path: `/work-shifts`,
        name: `workShifts`,
        component: WorkShifts,
        meta: {requiresAuth: true}
    },
    {
        path: `/materials`,
        name: `materials`,
        component: MaterialDirectory,
        meta: {requiresAuth: true}
    },
    {
        path: `/material-groups`,
        name: `materialGroups`,
        component: MaterialGroups,
        meta: {requiresAuth: true}
    },
    {
        path: `/material-targets`,
        name: `materialTargets`,
        component: MaterialTargets,
        meta: {requiresAuth: true}
    },
    {
        path: `/material-using`,
        name: `materialUsing`,
        component: MaterialUsing,
        meta: {requiresAuth: true}
    },
    {
        path: `/charts`,
        name: `charts`,
        component: Charts,
        meta: {requiresAuth: true}
    },
    {
        path: `/gallery`,
        name: `gallery`,
        component: Gallery,
        meta: {requiresAuth: true}
    },
    {
        path: `/reports-management`,
        name: `reports-management`,
        component: ReportsManagement,
        meta: {requiresAuth: true}
    },
    {
        path: `/alerts-management`,
        name: `alerts-management`,
        component: AlertsManagement,
        meta: {requiresAuth: true}
    },
    {
        path: `/user-groups`,
        name: `user-groups`,
        component: UserGroups,
        meta: {requiresAuth: true}
    },
    {
        path: `/telegram-datepicker`,
        name: `telegram-datepicker`,
        component: TelegramDatepicker,
        meta: {requiresAuth: false}
    },
    {
        path: `/tasks`,
        name: `tasks`,
        component: Tasks,
        meta: {requiresAuth: true}
    },
    {
        path: `/tasks/:id`,
        name: `task-card`,
        component: TaskCard,
        meta: {requiresAuth: true}
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve) => {
            setTimeout(() => {
                if (savedPosition) {
                    resolve(savedPosition);
                } else if (from.name === `task-card` && to.name === `tasks`) {
                    const {id} = from.params;
                    const elem = document.getElementById(id);
                    if (elem) {
                        resolve({el: id});
                    } else {
                        resolve();
                    }
                } else {
                    resolve({top: 0});
                }
            }, navigator?.connection?.downlink > 4 ? 1000 : 2000);
        })
    }
});

const guard = (to, from, next) => {
    if (to.matched.some(({meta}) => meta.requiresAuth)) {
        if (!store.getters.isAuthorized) {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })
        } else {
            next()
        }
    } else {
        next()
    }
}
router.beforeEach((to, from, next) => {
    if (store.state.appInitialized) {
        guard(to, from, next);
    } else {
        store.dispatch(`initializeApp`)
            .then(() => guard(to, from, next));
    }
});

export default router
