<template>
    <b-overlay :show="onProcess" rounded="sm">
        <b-card class="mt-3" :header="name"
                header-class="font-weight-bold" header-bg-variant="dark" header-text-variant="light"
                @click="closeContextMenu"
        >
            <template #header v-if="canEdit">
                <TaskHeader v-model="name" @rightClick="onRightClick"/>
            </template>

            <b-container fluid v-if="!onProcess">
                <b-row>
                    <b-col lg="8">
                        <div v-if="!isCreate">
                            <div v-if="isTaskClosed">
                                <span class="text-success small">Закрыта</span>
                            </div>
                            <div v-if="isTaskExpired">
                        <span class="text-danger small">
                            <b>Просрочена:</b> {{ Math.abs(getDaysToEnd) }} дня(ей)
                        </span>
                            </div>
                            <div v-if="getDaysToEnd >= 0 && getDaysToEnd <= 10">
                        <span class="text-warning small">
                            <b>Осталось {{ getDaysToEnd }} дня(ей)</b>
                        </span>
                            </div>
                        </div>
                        <TaskDescription v-model="description" :can-edit="canEdit" @rightClick="onRightClick"/>
                        <TaskFiles :files="files" @onUpload="uploadFile"
                                   @removeFile="id => onRemoveFile({id, taskId: $route.params.id})" :can-edit="canEdit"
                                   :uploadFilesInProcess="uploadFilesInProcess"/>
                        <CommentsBlock v-if="!isCreate"
                                       :comments="comments"
                                       :logs="logs"
                                       :can-edit="canEdit"
                                       :canCreateReportComment="canCreateReportComment"
                                       @addComment="onAddComment"
                                       @removeComment="onRemoveComment"
                                       @editComment="onEditComment"
                                       @removeFile="onRemoveFile"
                                       @onUpload="uploadFile"
                                       class="mt-4"
                                       @rightClick="onRightClick"
                        />
                    </b-col>
                    <b-col lg="4">
                        <TaskStatus v-if="!isCreate" v-model="status" :can-edit="canEdit"/>
                        <TaskAuthor v-if="!isCreate && author" :value="author"/>
                        <TaskResponsible v-model="responsible" :can-edit="canEdit" :value="responsible"/>
                        <TaskExecutors :items="executors" @change="addExecutor" @remove="removeExecutor"
                                       :can-edit="canEdit"/>
                        <TaskBuildingObject v-model="object" :can-edit="canEdit"/>
                        <TaskDate
                                :dateVariant="dateVariant"
                                :end="end"
                                :control="control"
                                :can-edit="canEdit"
                                :cron="cron"
                                :initCron="initCron"
                                @dateVariantChange="onDateVariantChange"
                                @endChange="onEndChange"
                                @controlChange="onControlChange"
                                @cronChanged="onCronChanged"
                        />
                        <ActionBlock @create="onCreateClick"
                                     @update="onUpdateClick"
                                     @close="goToTasksPage"
                                     :isCreate="isCreate"
                                     :disabledCreate="onProcess || !canCreateTask"
                                     :disabledUpdate="onProcess"
                        />
                    </b-col>
                </b-row>
            </b-container>
            <b-toast id="context-menu" static no-auto-hide
                     :style="`position: absolute; left:${toastX}; top:${toastY};`"
                     header-class="d-none"
                     v-model="showContextMenu"
            >
                <div class="p-1 text-center">
                    <b-button size="sm" title="Копировать" @click="onCopyClick" class="m-2">
                        Копировать
                        <i class="fas fa-copy mx-1"></i>
                    </b-button>
                    <b-button size="sm" title="Вставить" variant="info" :disabled="!rightClickTarget"
                              @click="onPasteClick">
                        Вставить
                        <i class="fas fa-paste mx-1"></i>
                    </b-button>
                </div>
            </b-toast>
        </b-card>
    </b-overlay>
</template>

<script>
import CommentsBlock from "@/views/Tasks/TaskCard/CommentsBlock/CommentsBlock";
import TaskHeader from "@/views/Tasks/TaskCard/TaskHeader/TaskHeader";
import TaskDescription from "@/views/Tasks/TaskCard/TaskDescription/TaskDescription";
import TaskFiles from "@/views/Tasks/TaskCard/TaskFiles/TaskFiles";
import TaskStatus from "@/views/Tasks/TaskCard/TaskStatus/TaskStatus";
import TaskAuthor from "@/views/Tasks/TaskCard/TaskAuthor/TaskAuthor";
import TaskResponsible from "@/views/Tasks/TaskCard/TaskResponsible/TaskResponsible";
import TaskExecutors from "@/views/Tasks/TaskCard/TaskExecutors/TaskExecutors";
import TaskBuildingObject from "@/views/Tasks/TaskCard/TaskBuildingObject/TaskBuildingObject";
import TaskDate from "@/views/Tasks/TaskCard/TaskDate/TaskDate";
import ActionBlock from "@/views/Tasks/TaskCard/ActionBlock/ActionBlock";
import Vue from "vue";

export default {
    name: "TaskCard",
    components: {
        CommentsBlock,
        TaskHeader,
        TaskDescription,
        TaskFiles,
        TaskStatus,
        TaskAuthor,
        TaskResponsible,
        TaskExecutors,
        TaskBuildingObject,
        TaskDate,
        ActionBlock
    },
    computed: {
        onProcess() {
            return this.$store.state.onProcess;
        },
        canEdit() {
            const {author, currentUser, isCreate, isAdmin} = this;
            return isCreate ? true : (currentUser?.userId === author?.user_id || isAdmin);
        },
        isCreate() {
            return this.$route.params.id === `new`;
        },
        users() {
            return this.$store.state.tasksManagement.shortUsers;
        },
        isAdmin() {
            return this.$store.getters.isAdmin;
        },
        objects() {
            return this.$store.state.objectsInProcess;
        },
        availableStatuses() {
            return this.$store.state.tasksManagement.availableStatuses;
        },
        canCreateTask() {
            const {name, responsible, isFixed, end, cron} = this;
            return name && responsible && (isFixed ? end : cron);
        },
        isFixed() {
            return this.dateVariant === `fixed`;
        },
        currentUser() {
            return this.$store.getters.currentUser;
        },
        canCreateReportComment() {
            const {author, responsible, currentUser} = this;
            return currentUser?.userId === author?.user_id || currentUser?.userId === responsible?.user_id;
        },
        isTaskClosed() {
            return this.savedStatus === 1;
        },
        isTaskExpired() {
            const {end, isTaskClosed, $moment} = this;
            return (end && !isTaskClosed) ? $moment().isAfter(end) : false;
        },
        getDaysToEnd() {
            const {end, cronDateFormat, isTaskClosed, $moment} = this;
            if (end && !isTaskClosed) {
                return $moment(end).diff($moment(), 'days');
            } else if (cronDateFormat) {
                return $moment(cronDateFormat).diff($moment(), 'days');
            } else {
                return -1;
            }
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isTelegramWebApps() {
            return this.$store.getters.isTelegramWebApps;
        }
    },
    data() {
        return {
            showContextMenu: false,
            name: ``,
            description: ``,
            files: [],
            comments: [],
            logs: [],
            status: 0,
            savedStatus: 0,
            author: null,
            responsible: null,
            executors: [],
            object: null,
            uploadData: null,
            end: this.$moment().add(1, 'days').set({hour: 10, minute: 0}).format("YYYY-MM-DDTkk:mm"),
            dateVariant: `fixed`,
            control: null,
            cron: '00 10 * * *',
            uploadFilesInProcess: false,
            initCron: `00 10 * * *`,
            toastX: null,
            toastY: null,
            selection: null,
            rightClickTarget: null,
            indexPosition: null
        }
    },
    mounted() {
        this.runPreloader();
        this.$store.dispatch(`getShortUsers`)
            .then(users => {
                this.$store.commit(`setShortUsers`, users);
                return Promise.resolve();
            })
            .then(() => this.$store.dispatch(`getObjectsInProcess`))
            .then(() => {
                if (this.isCreate) {
                    return Promise.resolve();
                } else {
                    return this.init();
                }
            })
            .catch(this.showErrorModal)
            .finally(this.stopPreloader);
    },
    methods: {
        onCreateClick() {
            this.stopPreloader();
            const {$store, _preparePayload, goToTasksPage} = this;
            $store.dispatch(`createTask`, _preparePayload())
                .then(goToTasksPage)
                .finally(this.stopPreloader);
        },
        goToTasksPage() {
            const {$router, $route} = this;
            const {params} = $route;
            $router.push(`/tasks#${params.id}`);
        },
        onUpdateClick() {
            this.stopPreloader()
            const {$store, $route: {params}, _preparePayload, goToTasksPage} = this;
            $store.dispatch(`updateTask`, {
                id: params.id,
                payload: _preparePayload()
            })
                .then(goToTasksPage)
                .finally(this.stopPreloader);
        },
        _preparePayload() {
            const {
                name, description, responsible, executors, object, end, control, cron, $moment, $store,
                isFixed, files, status
            } = this;
            const payload = {
                name,
                description,
                responsible,
                executors,
                object,
                status: status,
                files: files.map(el => el.id)
            }
            if (isFixed) {
                payload.endDate = $moment(end);
                if (control) {
                    payload.controlDate = $moment(control);
                }
            } else {
                payload.repeatCron = cron;
            }
            return payload;
        },
        init() {
            this.runPreloader();
            const {$route: {params}, $store, $moment} = this;
            return $store.dispatch(`getTaskById`, params.id)
                .then(res => {
                    const {
                        name, description, status, author, ResponsiblePerson, building_process, endDate, controlDate,
                        repeatCron, files, executors, Building
                    } = res;
                    this.name = name;
                    this.description = description;
                    this.savedStatus = status;
                    this.status = status;
                    this.author = this.users.find(el => el.user_id === author);
                    this.responsible = ResponsiblePerson;
                    this.files = files;
                    this.executors = this.users.filter(el => executors.some(e => e.user_id === el.user_id));
                    if (building_process) {
                        const f = this.objects.find(el => el.building_process_id === building_process);
                        const b = Building && Building.bo ? {object_name: Building.bo.name} : null;
                        this.object = f || b;
                    }
                    if (endDate) {
                        this.end = $moment(endDate).format("YYYY-MM-DDTkk:mm");
                    }
                    if (controlDate) {
                        this.control = $moment(controlDate).format("YYYY-MM-DDTkk:mm");
                    }
                    if (repeatCron) {
                        this.cron = repeatCron;
                        this.initCron = repeatCron;
                        this.dateVariant = `period`;
                    }
                })
                .then(this.getComments)
                .then(comments => {
                    this.comments = comments;
                    return Promise.resolve();
                })
                .then(this.getLogs)
                .then(logs => {
                    this.logs = logs;
                    return Promise.resolve();
                });
        },
        uploadFile(file) {
            this.uploadFilesInProcess = true;
            let fd = new FormData();
            fd.append(`file`, file)
            this.$store.dispatch(`uploadTaskFile`, {payload: fd})
                .then(file_ => this.files.push(file_))
                .finally(() => this.uploadFilesInProcess = false);
        },
        addExecutor(data) {
            this.executors.push(data);
            //Добавим Помощника к задаче
        },
        removeExecutor(data) {
            console.log(data);
            this.executors = this.executors.filter(el => el.user_id !== data.user_id);
            //Удалим Помощника из задачи
        },
        onDateVariantChange(v) {
            this.dateVariant = v;
        },
        onEndChange(v) {
            this.end = v;
        },
        onControlChange(v) {
            this.control = v;
        },
        onCronChanged(v) {
            this.cron = v;
        },
        onAddComment(v) {
            const {$route: {params}} = this;
            this.$store.dispatch(`addComment`, {
                taskId: Number(params.id),
                value: v.value,
                files: v.files,
                isReportComment: v.isReportComment
            })
                .then(this.getComments)
                .then(comments => {
                    this.comments = comments;
                    return Promise.resolve();
                })
                .then(this.getLogs)
                .then(logs => {
                    this.logs = logs;
                    return Promise.resolve();
                });
        },
        onRemoveComment(id) {
            const {$store, init, $route: {params}} = this;
            $store.dispatch(`deleteComment`, {id, task: {id: Number(params.id)}}).then(init);
        },
        onEditComment({id, text}) {
            const {$store, name, init, $route: {params}} = this;
            $store.dispatch(`editComment`, {id, payload: {value: text, task: {name, id: Number(params.id)}}}).then(init);
        },
        onRemoveFile(payload) {
            this.$store.dispatch(`deleteFile`, payload)
                .then(() => {
                    this.files = this.files.filter(el => el.id !== payload.id);
                    return Promise.resolve();
                })
                .then(this.getComments)
                .then(comments => {
                    this.comments = comments;
                    return Promise.resolve();
                })
                .then(this.getLogs)
                .then(logs => {
                    this.logs = logs;
                    return Promise.resolve();
                });
        },
        getComments() {
            this.runPreloader()
            const {$route: {params}} = this;
            return this.$store.dispatch(`getComments`, params.id).finally(this.stopPreloader);
        },
        getLogs() {
            this.runPreloader()
            const {$route: {params}} = this;
            return this.$store.dispatch(`getTaskLogs`, params.id).finally(this.stopPreloader);
        },
        runPreloader() {
            this.$store.commit(`setOnProcess`, true);
        },
        stopPreloader() {
            this.$store.commit(`setOnProcess`, false);
        },
        showErrorModal() {
            this.$bvModal.msgBoxOk(`Произошла ошибка сервера. Перезагрузите страницу.`, {
                okVariant: `danger`
            })
        },
        onRightClick({e, t}) {
            const {isTelegramWebApps, isMobile} = this;
            if (isTelegramWebApps && !isMobile) {
                this.rightClickTarget = t.$el;
                this.indexSelectionStart = document.activeElement.selectionStart;
                this.indexSelectionEnd = document.activeElement.selectionEnd;
                this.selection = window.getSelection().toString();
                this.toastX = `${e.layerX}px`;
                this.toastY = `${e.layerY}px`;
                e.preventDefault();
                this.showContextMenu = true;
            }
        },
        closeContextMenu() {
            this.showContextMenu = false;
            this.selection = null;
        },
        async onCopyClick() {
            if (navigator.clipboard && window.isSecureContext) {
                await navigator.clipboard.writeText(this.selection);
            }
        },
        async onPasteClick() {
            if (navigator.clipboard && window.isSecureContext) {
                let element = this.rightClickTarget;
                let origString = element.value;
                let stringToAdd = await navigator.clipboard.readText();
                const {indexSelectionStart, indexSelectionEnd} = this;
                const text = origString.slice(0, indexSelectionStart) + stringToAdd + origString.slice(indexSelectionEnd);
                Vue.set(element, 'value', text);
                element.dispatchEvent(new Event('input'))
            }
        }
    }
}
</script>
<style lang="scss">
.btn.dropdown-toggle-no-caret {
  padding: 0 5px;
  border: 0;
  box-shadow: none !important;
  font-size: 14px;
  color: black;
  text-decoration: none;
}

.dateVariant {
  position: absolute !important;
  top: 15px;
  right: 0;
}
</style>