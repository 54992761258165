<template>
    <div>
        <b-card v-if="isLogMessage" bg-variant="light" body-class="p-2">
            <span class="ml-1 mt-1 small text-info">{{ $moment(item.createdAt).format(`DD-MM-yyyy (dd) HH:mm`) }}</span>
            <div v-html="item.message.replaceAll(`\\n`, `<p>`)"></div>
        </b-card>
        <div v-else>
            <div class="d-flex">
                <UserCard :value="item.Author"/>
                <span class="ml-1 mt-1 small text-info">{{ $moment(item.createdAt).format(`DD-MM-yyyy (dd) HH:mm`)
                    }}</span>
                <span v-if="item.isReport" class="small text-danger mt-1 ml-2">Отчетный</span>
            </div>
            <div class="d-flex my-1">
                <div class="w-100 comment-read" v-if="!isEdit" v-html="Urlify(item.value || item.message)">
                </div>
                <b-form-textarea v-if="isEdit" v-model="comment" size="sm" placeholder="Напишите свой комментарий..."
                                 rows="3"
                                 :ref="`comment_${item.id}`"
                                 @contextmenu="e => $emit(`rightClick`, {e, t: this.$refs['comment_' + item.id]})"
                />
                <div class="d-flex ml-1">
                    <i v-if="canEdit && !isEdit" class="fas fa-pen text-center small" title="Редактировать"
                       @click="onStartEditClick"/>
                    <i v-if="canEdit && !isEdit" class="fas fa-times text-danger small ml-2" title="Удалить"
                       @click="removeItem"/>
                    <i v-if="isEdit" class="fab fa-telegram-plane" title="Отправить" @click="editComment"></i>
                    <i v-if="isEdit" class="fas fa-ban ml-2 text-danger" title="Отменить"
                       @click="onCancelEditClick"></i>
                </div>
            </div>
            <FileList :list="item.files" @remove="removeFile" :can-edit="canEdit"/>
        </div>
    </div>
</template>

<script>
import UserCard from "@/views/Tasks/UserCard/UserCard";
import FileList from "@/views/Tasks/TaskCard/TaskFiles/FileList/FileList";
import Urlify from "@/utils/Urlify";

export default {
    name: "CommentItem",
    components: {
        UserCard,
        FileList
    },
    props: {
        item: Object,
        canEdit: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isLogMessage() {
            return this.item.event;
        }
    },
    data() {
        return {
            isEdit: false,
            comment: ``
        }
    },
    methods: {
        onStartEditClick() {
            this.comment = this.item.value;
            this.isEdit = true;
        },
        removeItem() {
            this.$bvModal.msgBoxConfirm(`Вы уверены что хотите удалить комментарий?`, {
                cancelTitle: `Отмена`
            })
                .then(confirm => {
                    if (confirm) {
                        this.$emit(`removeItem`);
                    }
                })
        },
        removeFile(id) {
            this.$emit(`removeFile`, id);
        },
        editComment() {
            this.$emit(`editItem`, this.comment)
            this.isEdit = false;
        },
        onCancelEditClick() {
            this.isEdit = false;
            this.comment = ``;
        },
        Urlify
    }
}
</script>

<style scoped>
i {
    cursor: pointer;
}

.comment-read {
    white-space: pre-wrap;
}
</style>