<template>
    <div class="d-flex justify-content-end mt-2">
        <b-button size="sm" @click="$emit(`close`)" class="mx-2">
            К списку задач
        </b-button>
        <b-button variant="success" size="sm" @click="$emit(`create`)" v-if="isCreate" :disabled="disabledCreate">
            Создать задачу
        </b-button>
        <b-button variant="success" size="sm"  @click="$emit(`update`)" v-if="!isCreate" :disabled="disabledUpdate">
            Сохранить изменения
        </b-button>
    </div>
</template>

<script>
export default {
    name: "ActionBlock",
    props: {
        isCreate: Boolean,
        disabledCreate: Boolean,
        disabledUpdate: Boolean
    }
}
</script>

<style scoped>

</style>